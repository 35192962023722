@import '../variables.sass';
@import '../mixins.sass';

.career {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: 110vh;
    & .offers p {
        font-size: 1.3rem;
        line-height: 1.7em;
        word-spacing: 1.5px;
        &:first-child {
            margin-top: 3vh; } }
    & table, th, td {
        width: 90%;
        table-layout: fixed;
        margin: 5vh auto 0 auto;
        border: 1px solid $dark-color;
        border-collapse: collapse;
        & th, td {
            font-size: 1.2rem;
            padding: 7px;
            text-align: center; } }
    & .open-offer-modal-btn {
        font-family: 'Cinzel', serif;
        font-size: 1.2rem;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer; }
    & .date, & .location {
        width: 25%; }
    & .join {
        @include button;
        margin: 5vh 10vw 10vh auto;
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        position: relative;
        animation: join-back-animation 4s infinite;
        @keyframes join-back-animation {
            0% {
                background-color: $details-color; }
            50% {
                background-color: $dark-color; }
            100% {
                background-color: $details-color; } }
        & a {
            font-size: 1.3rem;
            text-decoration: none;
            color: $white-color; }
        &:hover {
            cursor: pointer; } }
    & .career-modal-back {
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(2, 2, 2, .8);
        height: 100%;
        width: 100%;
        z-index: 30; } }

@media (max-width: 20000px) {
    * {
        font-size: 16px; } }

@media (max-width: 1260px) {
    * {
        font-size: 15px; } }

@media (max-width: 1160px) {
    * {
        font-size: 14px; } }

@media (max-width: 830px) {
    .career table {
        width: 100%; } }

@media (max-width: 700px) {
    .date, .job-offer-date {
        display: none; }
    .career table th, .career table td, .career th th, .career th td, .career td th, .career td td, .career .open-offer-modal-btn {
        font-size: 1rem; } }

@media (max-width: 420px) {
    .location, .job-offer-location {
        display: none; } }
