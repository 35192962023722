@import '../variables.sass';
@import '../mixins.sass';

.shop-header {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 28vh;
    width: 100%;
    background-color: $white-color;
    background-image: url('../../../images/back_shop.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: grayscale(100%);
    & .advantages {
        justify-content: space-around;
        align-items: center;
        padding: .3em 0;
        background-color: $dark-color;
        width: 100%;
        & div {
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: $white-color;
            & i {
                color: $white-color;
                padding-right: .6em; } } }
    & .search-and-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 5vw;
        width: 95%;
        & .search {
            justify-content: flex-start;
            align-items: center;
            color: $white-color;
            margin-left: 2rem;
            & input {
                color: $white-color;
                background-color: transparent;
                backdrop-filter: $blur-effect;
                border: $white-border-thin;
                margin: 0 .8em;
                border-radius: 6px;
                height: 30px;
                outline: none; }
            & .fa-search {
                font-size: 2rem; } }
        & .logo-border {
            @include border-logo-style;
            cursor: pointer;
            &:hover {
                background-color: $white-color;
                & p {
                    color: $dark-color; } }
            & a {
                text-decoration: none; }
            & .logo-name {
                @include logo-name-style;
                color: $white-color;
                &:hover {
                    background-color: transparent00; } } } }
    & .shop-menu {
        justify-content: space-around;
        align-items: center;
        align-self: flex-start;
        background-color: $dark-color;
        padding: .3em 0;
        width: 100%;
        list-style: none;
        & .active {
            border-bottom: 1px solid $white-color;
            text-decoration: none; }
        & .not-active {
            border-bottom: 1px solid $dark-color;
            text-decoration: none; }
        & .shop-menu-btn {
            @include button;
            display: flex;
            justify-content: space-around;
            border-style: none;
            font-size: 1.2rem;
            cursor: pointer; } } }

@media (min-width: 1261px) {
    .advantages {
        display: flex;
        div {
            i {
                font-size: 1.2rem; }
            p {
                font-size: 1.2rem; } } }
    .search {
        display: flex;
        width: 50%;
        input {
            width: 380px; } }
    .shop-menu {
        display: flex; } }

@media (max-width: 1260px) {
    .advantages {
        display: flex;
        div {
            i {
                font-size: 1.2rem; }
            p {
                font-size: 1.2rem; } } }
    .search {
        display: flex;
        width: 60%;
        input {
            width: 230px; } }
    .shop-menu {
        display: flex; } }

@media (max-width: 1160px) {
    .search {
        input {
            flex-direction: column; } } }

@media (max-width: 800px) {
    .advantages {
        div {
            i {
                font-size: 1rem; }
            p {
                font-size: 1rem; } } } }

@media (max-width: 660px) {
    .advantages {
        div {
            i {
                font-size: .8rem; }
            p {
                font-size: .8rem; } } }
    .search {
        width: 80%;
        input {
            width: 280px; }
        .fa-search {
            display: none; } } }

@media (max-width: 550px) {
    .advantages {
        display: none; }
    .search {
        input {
            width: 240px; } } }

@media (max-width: 360px) {
    .shop-menu {
        display: grid;
        grid-template-columns: 20px repeat(3, minmax(30px, 55px));
        grid-template-rows: repeat(2, 23px);
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            .shop-menu-btn {} }
        a:first-child {
            grid-row: 1 / 3; } } }
@media (max-width: 360px) {
    .shop-menu {
        display: grid;
        grid-template-columns: repeat(2, minmax(30px, 55px));
        grid-template-rows: repeat(auto-fill, 23px);
        grid-gap: .5em;
        a:first-child {
            grid-row: 1 / 1;
            grid-column: 1 / 3; } } }

@media (min-width: 1261px) and (orientation: portrait) {
    .advantages {
        display: flex;
        div {
            i {
                font-size: 1.2rem; }
            p {
                font-size: 1.2rem; } } } }

@media (max-width: 1260px) and (orientation: portrait) {
    .advantages {
        display: flex;
        div {
            i {
                font-size: 1.2rem; }
            p {
                font-size: 1.2rem; } } }
    .search-and-logo {
        .logo-border {
            height: 150px;
            width: 150px; } } }

@media (max-width: 1160px) and (orientation: portrait) {
    .search-and-logo {
        .logo-border {
            height: 130px;
            width: 130px;
            .logo-name {
                font-size: 1.9rem; } } } }

@media (max-width: 800px) and (orientation: portrait) {
    .advantages {
        div {
            i {
                font-size: 1rem; }
            p {
                font-size: 1rem; } } }
    .search-and-logo {
        .logo-border {
            height: 110px;
            width: 110px;
            .logo-name {
                font-size: 1.8rem; } } } }

@media (max-width: 660px) and (orientation: portrait) {
    .advantages {
        div {
            i {
                font-size: .8rem; }
            p {
                font-size: .8rem; } } }
    .search-and-logo {
        .logo-border {
            height: 100px;
            width: 100px;
            .logo-name {
                font-size: 1.7rem; } } } }

@media (max-width: 550px) and (orientation: portrait) {
    .shop-header {
        min-height: 18vh;
        .advantages {
            display: none; }
        .search-and-logo {
            .logo-border {
                height: 90px;
                width: 90px;
                margin: 2vh 0;
                .logo-name {
                    font-size: 1.6rem; } } } } }

@media (max-width: 470px) and (orientation: portrait) {
    .shop-header .search-and-logo {
        .logo-border {
            height: 80px;
            width: 80px;
            .logo-name {
                font-size: 1.5rem; } }
        .search {
            display: none; } } }

@media (max-width: 360px) and (orientation: portrait) {
    .shop-header .search-and-logo {
        .logo-border {
            height: 70px;
            width: 70px;
            margin: 1.5vh 0;
            .logo-name {
                font-size: 1.4rem; } } } }

@media (max-width: 1601px) and (orientation: landscape) {
    .search {
        width: 50%;
        input {
            width: 350px; } } }

@media (min-width: 1601px) and (orientation: landscape) {
    .search-and-logo {
        .logo-border {
            height: 155px;
            width: 155px;
            .logo-name {
                font-size: 2rem; } } } }

@media (max-width: 1600px) and (orientation: landscape) {
    .search-and-logo {
        .logo-border {
            height: 150px;
            width: 150px;
            .logo-name {
                font-size: 2rem; } } } }

@media (max-width: 1360px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 140px;
        width: 140px;
        .logo-name {
            font-size: 1.7rem; } } }

@media (max-width: 1260px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 120px;
        width: 120px;
        .logo-name {
            font-size: 1.6rem; } } }

@media (max-width: 1160px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 100px;
        width: 100px;
        .logo-name {
            font-size: 1.5rem; } } }

@media (max-width: 800px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 90px;
        width: 90px;
        .logo-name {
            font-size: 1.4rem; } } }

@media (max-width: 660px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 80px;
        width: 80px;
        .logo-name {
            font-size: 1.3rem; } } }

@media (max-width: 550px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 70px;
        width: 70px;
        .logo-name {
            font-size: 1.2rem; } } }

@media (max-width: 470px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 60px;
        width: 60px;
        .logo-name {
            font-size: 1.1rem; } } }

@media (max-width: 360px) and (orientation: landscape) {
    .search-and-logo .logo-border {
        height: 55px;
        width: 55px;
        .logo-name {
            font-size: 1.1rem; } } }
