@import '../variables.sass';
@import '../mixins.sass';

.career-modal {
    @include modal-style;
    width: 80%;
    left: 10vw;
    z-index: 30;
    & div p {
        padding-top: 1.3vh;
        & span:first-child {
            font-size: 1.2rem;
            font-weight: 700; }
        & span:last-child {
            white-space: pre-wrap; } }
    & h2 {
        margin-bottom: .5em;
        border-bottom: 1px solid $dark-color; }
    & .close-btn {
        @include button;
        font-size: 1.06rem;
        color: $white-color;
        margin: 3vh 3vh 1vh auto;
        width: 4.38rem;
        height: 4.38rem;
        &:hover {
            @include hover-button;
            background-color: $white-color; } }
    & .send-cv-btn {
        @include button;
        color: $white-color;
        margin: 3vh 3vh 3vh auto;
        width: 7.2rem;
        height: 7.2rem;
        a {
            font-size: 1.3rem; }
        &:hover {
            @include hover-button; } } }

@media (max-width: 550px) {
    .career-modal {
        width: 90%;
        left: 5vw; } }

@media (max-width: 360px) {
    .career-modal {
        width: 96%;
        left: 2vw; } }
