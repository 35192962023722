@import '../variables.sass';
@import '../mixins.sass';

.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    margin: 2.5vh .7vw;
    background-color: $white-color;
    & .product-description {
        min-height: 20%;
        padding: .8em 0 .4em 0;
        width: 95%;
        & div {
            min-height: 67%; }
        & p:first-child {
            font-size: 1.1em;
            font-weight: bold; } }
    & .product-photo {
        position: relative;
        min-height: 52%;
        background-color: $white-color;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            transition: all .6s; }
        &:hover::before {
            background-color: rgba(15, 15, 15, .8); }
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        & .product-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 50%;
            & p {
                font-size: .9em;
                letter-spacing: .03em;
                color: transparent;
                padding: .5em; } }
        &:hover p {
            color: $white-color; }
        & .fa-info-circle {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 2em;
            color: $dark-color;
            margin: .4em;
            background-color: $white-color;
            border: 1px solid $white-color;
            border-radius: 50%; }
        &:hover .fa-info-circle {
            display: none; } }
    & .choose-quantity {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: .4em 0 .8em 0;
        width: 95%;
        min-height: 28%;
        & p {
            margin: .5em 0 .7em 0; }
        & input {
            font-size: 1em;
            font-family: 'Cinzel', serif;
            border: $black-border-thin;
            border-radius: 6px;
            width: 60px;
            outline: none; }
        & .warning {
            display: block;
            font-size: .75em;
            font-weight: 900;
            color: $details-color;
            margin: .5em;
            opacity: 0; }
        & .add-to-cart {
            @include button;
            font-size: 1.1rem;
            padding: 0.22em;
            border: $black-border-thin;
            border-radius: 6px;
            width: 73%;
            &:hover {
                @include hover-button; } } } }
