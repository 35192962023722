@import '../variables.sass';
@import '../mixins.sass';

.newsletter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white-color;
    background-color: $dark-color;
    min-height: 27vh;
    & .newsletter-icon {
        display: flex;
        align-items: center;
        padding: 2vh;
        & .fa-newspaper {
            font-size: 2.1rem;
            margin-right: .8em; }
        & p {
            font-size: 2.1rem; } }
    & p {
        font-size: 1.4rem; }
    & form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3vh 0;
        width: 100%;
        & label {
            font-size: 1.4rem; } }
    & input {
        color: $white-color;
        border-radius: 6px;
        outline: none; }
    & [type='email'] {
        margin: 0 3vw 0 1vw;
        border: $white-border-thin;
        background-color: $dark-color;
        height: 1.6rem; }
    & [type='submit'] {
        @include button;
        font-size: 1.4rem;
        border-radius: 6px;
        height: 2.7rem;
        width: 7.5rem; }
    & [type='submit']:hover {
        @include hover-button;
        border: $white-border-thin; } }
.contact_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    min-height: 70vh;
    & .contact_form {
        display: grid;
        grid-gap: 2em;
        justify-content: center;
        align-items: center;
        width: 80%;
        & .address {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            text-align: center;
            flex-basis: 50%;
            align-self: flex-start;
            margin-top: 3vh;
            height: 100%;
            & p {
                width: 80%;
                margin-top: 2vh; } }
        & .form {
            flex-basis: 50%;
            padding: 0 1vw 2vh 1vw;
            & form {
                display: flex;
                flex-direction: column; }
            & textarea, & input:not([type='file']) {
                background-color: transparent;
                backdrop-filter: $blur-effect;
                border: $black-border-thin;
                border-radius: 6px;
                outline: none; }
            & textarea {
                width: 80%;
                height: 13vh; }
            & input {
                height: 1.6rem; }
            & label {
                margin-top: 1vh; }
            & [type='submit'] {
                @include button;
                font-size: 1.4rem;
                color: $dark-color;
                border: $black-border-thin;
                margin-top: 4vh;
                height: 2.7rem;
                width: 7.5rem; }
            & [type='submit']:hover {
                color: $white-color;
                background-color: $dark-color;
                border: $black-border-thin;
                cursor: pointer; }
            & input:nth-last-child(2) {
                border: none;
                background-color: transparent; } } } }
.main-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    color: $white-color;
    padding: 0 .8rem;
    background-color: $dark-color;
    min-height: 3vh;
    p {
        color: $white-color;
        a {
            color: $white-color; } } }
@media (max-width: 20000px) {
    .contact_form {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(350px, 2fr));
        & .form input {
            width: 50%; }
        & .address p {
            font-size: 2rem; } } }

@media (max-width: 800px) {
    .main-footer {
        flex-direction: column;
        align-items: center;
        text-align: center; } }

@media (max-width: 550px) {
    .contact_form {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); } }

@media (max-width: 470px) {
    .newsletter {
        & form {
            flex-direction: column; }
        & input {
            margin: 1em 0; }
        & [type='email'] {
            margin: 0; } }
    .contact_form {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(300px, 2fr));
        & .form input {
            width: 80%; }
        & .address p {
            font-size: 1.6rem; } } }

@media (max-width: 360px) {
    .contact_form {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); } }
