@import '../variables.sass';
@import '../mixins.sass';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    & .main {
        font-family: 'Cinzel', serif;
        background-color: $white-color;
        overflow: hidden;
        & .header {
            background: url('../../../images/back_11.jpg') no-repeat center center;
            background-size: cover;
            & .home {
                min-height: 100vh; } }
        & section {
            font-size: 1.3rem;
            line-height: 1.7;
            word-spacing: 2.5px;
            margin: 0 auto; }
        & article {
            margin: 0 auto;
            padding: 5em .5em;
            width: 75%; }
        & .contact {
            background: url('../../../images/back_11.jpg') no-repeat center top;
            background-size: cover;
            min-height: 100vh; }
        & h1 {
            font-size: 2.1rem;
            font-weight: 700;
            padding-bottom: 1.5em;
            text-align: center; }
        & h2 {
            font-size: 1.5rem; } } }

@media (max-width: 20000px) {
    * {
        font-size: 16px; } }

@media (max-width: 1260px) {
    * {
        font-size: 15px; } }

@media (max-width: 1160px) {
    * {
        font-size: 14px; } }

@media (max-width: 800px) {
    * {
        font-size: 13px; } }

@media (max-width: 550px) {
    * {
        font-size: 12px; } }

@media (max-width: 470px) {
    * {
        font-size: 11px; } }

@media (max-width: 360px) {
    * {
        font-size: 10px; } }
