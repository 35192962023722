@import '../variables.sass';
@import '../mixins.sass';

.about {
    & p {
        font-size: 1.3rem;
        margin-top: 2em;
        & span {
            font-size: 1em;
            font-weight: 700; } }
    & .numbers {
        display: grid;
        grid-template-columns: repeat(auto-fit, 270px);
        grid-column-gap: 2em;
        grid-row-gap: 10em;
        justify-content: center;
        text-align: center;
        background-color: $dark-color;
        letter-spacing: .07em;
        width: 100%;
        & div {
            display: flex;
            flex-direction: column;
            color: $white-color;
            & i {
                font-size: 3.2em; }
            & p {
                font-size: 1.3em;
                &:nth-child(2) {
                    color: $details-color;
                    font-weight: 700; } } } } }
