@import '../variables.sass';
@import '../mixins.sass';

.nav-menu {
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: clip-path 1.4s ease-in-out;
    z-index: 100;
    & .menu-logo-container {
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 60vw;
        background-color: $white-color;
        & .logo-border {
            @include border-logo-style;
            height: 18vw;
            width: 18vw;
            top: 0;
            left: 0;
            & .logo-name {
                @include logo-name-style; } } }
    & .navigation-menu-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        background-color: $dark-color;
        & .fa-times {
            font-size: 2.4rem;
            color: $white-color;
            margin: 3vh .9em 0 0;
            min-height: 5vh;
            text-align: end;
            transition: all .6s;
            cursor: pointer; }
        & .navigation-menu {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            height: 75%;
            & li {
                position: relative;
                transition: all .3s;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    transition: all .5s;
                    border-bottom: 3px solid $white-color;
                    cursor: pointer; }
                &:hover::before {
                    width: 100%; }
                & a {
                    font-size: 1.6rem;
                    color: $white-color;
                    text-decoration: none;
                    padding: 0 1.25em; } } } } }

@media (max-width: 20000px) {
    .menu-logo-container {
        display: flex;
        .logo-name {
            font-size: 3rem; } }
    .navigation-menu-container {
        width: 40vw; } }

@media (max-width: 1160px) {
    .menu-logo-container {
        display: none; }
    .navigation-menu-container {
        width: 100vw; } }
