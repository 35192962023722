@import '../variables.sass';
@import '../mixins.sass';

.shop-footer-wrapper {
    grid-column: 1 / 3;
    .shop-footer {
        display: grid;
        grid-template-rows: repeat(2, 3.7vh);
        grid-auto-rows: 3.7vh;
        text-align: center;
        letter-spacing: .03em;
        color: $white-color;
        background-color: $dark-color;
        min-height: 7.6vh;
        & .shop-footer-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Cinzel', serif;
            font-size: .9rem;
            color: $white-color;
            padding: .5em 0;
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer; }
        & a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9rem;
            color: $white-color;
            padding: .5em 0;
            text-align: center;
            text-decoration: none;
            cursor: pointer; }
        & .shop-footer-back {
            position: fixed;
            left: 0;
            top: 0;
            background-color: rgba(2, 2, 2, .8);
            backdrop-filter: $blur-effect;
            height: 100%;
            width: 100%; } } }
.copyrights {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1rem;
    padding: .8rem .8rem 0 .8rem;
    color: $white-color;
    background-color: $dark-color; }

@media (min-width: 551px) {
    .shop-footer {
        grid-template-columns: repeat(3, minmax(200px, 1fr)); } }

@media (max-width: 550px) {
    .shop-footer {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); } }

@media (max-width: 360px) {
    .shop-footer {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); } }
