@import '../variables.sass';
@import '../mixins.sass';

.cart {
    position: fixed;
    bottom: 12vh;
    height: 54.5vh;
    right: 4vw;
    border-radius: 6px;
    .small-cart {
        flex-direction: column;
        align-items: center;
        button {
            margin: 1.1rem auto;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer; }
        p {
            font-size: 1.2rem; } }
    .large-cart {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 54.5vh;
        width: 360px;
        background-color: $white-color;
        border-radius: 6px;
        & p {
            margin-top: .5em; } }
    > p {
        font-weight: 700;
        font-size: 1.2rem;
        color: $details-color; }
    & .fa-shopping-cart {
        font-size: 3.5rem;
        margin-bottom: .2em; }
    & .in_cart {
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $dark-color;
        border-bottom: 1px solid $dark-color;
        height: 65%;
        width: 80%;
        scroll: auto;
        letter-spacing: .01em;
        & .in_cart_products {
            overflow: auto;
            height: 85%;
            width: 100%;
            & > p {
                text-align: center;
                padding-top: .6rem; }
            & ul {
                padding-top: .6rem; }
            & li {
                display: flex;
                justify-content: space-between;
                list-style: none;
                & p {
                    width: 20%;
                    font-size: .85rem;
                    padding: 5px;
                    text-align: end; }
                & p:nth-child(2) {
                    width: 15%; }
                & p:first-child {
                    width: 65%;
                    text-align: start; } } }
        & p {
            font-size: 1.1rem;
            text-align: center; } }
    & .go-to-cart {
        @include button;
        font-size: 1.1rem;
        margin: 1.1rem auto;
        border: $black-border-thin;
        border-radius: 6px;
        width: 60%;
        height: 44px;
        &:hover {
            @include hover-button; } }
    & .shop-cart-back {
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(2, 2, 2, .8);
        backdrop-filter: $blur-effect;
        backdrop-filter: $blur-effect;
        height: 100%;
        width: 100%; } }

@media (max-width: 20000px) {
    .large-cart {
        display: flex; }
    .small-cart {
        display: none; } }

@media (max-width: 660px) {
    .cart {
        bottom: 9vh;
        height: 125px; }
    .large-cart {
        display: none; }
    .small-cart {
        display: flex; } }

@media (max-height: 700px) {
    .cart {
        bottom: 9vh;
        height: 125px; }
    .large-cart {
        display: none; }
    .small-cart {
        display: flex; } }
