* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    & .shop {
        display: grid;
        font-family: 'Cinzel', serif;
        min-height: 100vh;
        overflow: hidden;
        & h2 {
            font-size: 1.5rem;
            text-align: start; } } }

@media (max-width: 20000px) {
    * {
        font-size: 16px;
        & .shop {
            grid-template-columns: auto 430px; } } }

@media (min-width: 1261px) {
    * {
        font-size: 16px; } }

@media (max-width: 1260px) {
    * {
        font-size: 15px; } }

@media (max-width: 1160px) {
    * {
        font-size: 14px; } }

@media (max-width: 800px) {
    * {
        font-size: 13px; } }

@media (max-width: 660px) {
    .shop {
        grid-template-columns: auto 85px; } }

@media (max-width: 550px) {
    * {
        font-size: 12px; } }

@media (max-width: 470px) {
    * {
        font-size: 11px; } }

@media (max-width: 360px) {
    * {
        font-size: 10px; } }
