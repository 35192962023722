@import '../variables.sass';
@import '../mixins.sass';

.show-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 3vh;
    right: 2vw;
    mix-blend-mode: difference;
    z-index: 1;
    & a {
        text-decoration: none;
        margin-right: 3rem;
        & i {
            font-size: 2.2rem;
            color: $white-color; } }
    & i {
        font-size: 2.2rem;
        color: $white-color;
        cursor: pointer; } }

@media (max-width: 20000px) and (max-height: 500px) {
    .show-menu a i {
        font-size: 1.6em; }
    .show-menu i {
        font-size: 1.6em; } }
