@import '../variables.sass';

.recommendations {
    color: $white-color;
    min-height: 150vh;
    background-color: $dark-color;
    & h1 {
        margin: 0 auto;
        border-top: 1px solid $dark-color; }
    & .opinions {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        & div {
            display: flex;
            flex-direction: column;
            padding: 1.5em;
            margin: 2.7vh 1vw;
            border-bottom: 1px solid $white-color;
            border-radius: 6px;
            backdrop-filter: $blur-effect;
            & p {
                font-size: 1.3rem;
                font-family: 'JetBrains Mono', monospace; } }
        & div:nth-child(odd) {
            margin-left: 20%; }
        & div:nth-child(even) {
            margin-right: 20%; } }
    & p:last-child {
        align-self: flex-end;
        font-weight: 700;
        color: $details-color;
        margin-top: .6rem; } }
