@import '../variables.sass';
@import '../mixins.sass';

.shop-no-results {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10vh 3vw;
    min-height: 50vh;
    & .fa-searchengin {
        font-size: 3.2rem;
        margin-bottom: 4vh; }
    & p {
        font-size: 1.9rem;
        text-align: center; } }
