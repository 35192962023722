@import '../variables.sass';
@import '../mixins.sass';

.home {
    display: flex;
    justify-content: center;
    align-items: center;
    & .logo {
        position: fixed;
        top: 6vh;
        left: 2.37vw;
        mix-blend-mode: difference;
        cursor: pointer;
        z-index: 20;
        & .logo-border {
            mix-blend-mode: difference;
            @include border-logo-style;
            &:hover {
                background-color: $white-color;
                color: $dark-color; }
            & .logo-name {
                @include logo-name-style; } } }
    & .main-page {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        & .tagline {
            display: flex;
            flex-direction: column;
            font-family: 'Raleway Dots', cursive;
            text-align: center;
            letter-spacing: .07em;
            text-transform: uppercase;
            opacity: 0;
            transform: translateX(-3vw);
            & p {
                line-height: 2em;
                &:first-child {
                    transform: translateX(-1.3vw); }
                &:last-child {
                    line-height: 2em;
                    transform: translateX(+1.2vw);
                    & span {
                        font-family: 'Unica One', cursive;
                        text-transform: lowercase;
                        letter-spacing: .08em; } } } }
        & .fas {
            position: absolute;
            bottom: 4vh;
            right: calc(50vw - 35px);
            width: 4rem;
            text-align: center;
            margin: 1rem;
            border: none;
            cursor: pointer; } }
    & .side-bar {
        position: fixed;
        bottom: 0;
        right: 2vw;
        height: 80vh;
        mix-blend-mode: difference;
        & ul {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            list-style: none;
            height: 100%;
            & li {
                & a {
                    text-decoration: none;
                    & i {
                        font-size: 2.2rem;
                        color: $white-color;
                        text-decoration: none;
                        cursor: pointer; } } } } } }

@media (max-width: 20000px) and (orientation: portrait) {
    .home .logo {
        top: 4vh;
        .logo-border {
            height: 155px;
            width: 155px; }
        .logo-name {
            font-size: 2rem; } }
    .tagline p, {
        font-size: 7.5rem; }
    .tagline span {
        font-size: 8.4rem; }
    .home .main-page .fas {
        font-size: 3rem; } }

@media (max-width: 1600px) and (orientation: portrait) {
    .tagline p {
        font-size: 7rem; }
    .tagline span {
        font-size: 8.4rem; }
    .home .main-page .fas {
        font-size: 2.8rem; } }

@media (max-width: 1450px) and (orientation: portrait) {
    .tagline p {
        font-size: 6.5rem; }
    .tagline span {
        font-size: 8.1rem; } }

@media (max-width: 1360px) and (orientation: portrait) {
    .home .logo {
        top: 5vh; }
    .tagline p {
        font-size: 6rem; }
    .tagline span {
        font-size: 7.4rem; } }

@media (max-width: 1260px) and (orientation: portrait) {
    .home .logo {
        top: 4vh;
        .logo-border {
            height: 150px;
            width: 150px; } }
    .tagline p {
        font-size: 5.8rem; }
    .tagline span {
        font-size: 7rem; } }

@media (max-width: 1160px) and (orientation: portrait) {
    .home .logo {
        top: 3.5vh;
        .logo-border {
            height: 130px;
            width: 130px; }
        .logo-name {
            font-size: 1.9rem; } }
    .tagline p {
        font-size: 5.5rem; }
    .tagline span {
        font-size: 6.6rem; } }

@media (max-width: 800px) and (orientation: portrait) {
    .home .logo {
        top: 3vh;
        .logo-border {
            height: 110px;
            width: 110px; }
        .logo-name {
            font-size: 1.8rem; } }
    .tagline p {
        font-size: 5.2rem; }
    .tagline span {
        font-size: 6.3rem; } }

@media (max-width: 660px) and (orientation: portrait) {
    .home .logo {
        top: 2.6vh;
        .logo-border {
            height: 100px;
            width: 100px; }
        .logo-name {
            font-size: 1.7rem; } }
    .tagline p {
        font-size: 5rem; }
    .tagline span {
        font-size: 5.9rem; } }

@media (max-width: 550px) and (orientation: portrait) {
    .home .logo {
        top: 2.3vh;
        .logo-border {
            height: 90px;
            width: 90px; }
        .logo-name {
            font-size: 1.6rem; } }
    .tagline p {
        font-size: 4.6rem; }
    .tagline span {
        font-size: 5.4rem; }
    .home .main-page .fas {
        font-size: 2.7rem; } }

@media (max-width: 470px) and (orientation: portrait) {
    .home .logo {
        top: 2vh;
        .logo-border {
            height: 80px;
            width: 80px; }
        .logo-name {
            font-size: 1.5rem; } }
    .tagline p {
        font-size: 4rem; }
    .tagline span {
        font-size: 4.8rem; }
    .home .main-page .fas {
        font-size: 2.5rem; } }

@media (max-width: 360px) and (orientation: portrait) {
    .home .logo {
        top: 1.5vh;
        .logo-border {
            height: 70px;
            width: 70px; }
        .logo-name {
            font-size: 1.4rem; } }
    .tagline p {
        font-size: 3.7rem; }
    .tagline span {
        font-size: 4.3rem; }
    .home .main-page .fas {
        font-size: 2rem; } }

@media (max-width: 2000px) and (max-height: 500px) {
    .home {
        .tagline p, {
            font-size: 3.8rem; }
        .tagline span {
            font-size: 4.3rem; }
        .fas {
            font-size: 2rem; }
        .side-bar ul li a i {
            font-size: 1.6em; } } }

@media (min-width: 1601px) and (orientation: landscape) {
    .logo {
        top: 4vh; }
    .logo-border {
        height: 155px;
        width: 155px; }
    .logo-name {
        font-size: 2rem; }
    .tagline p, {
        font-size: 7.5rem; }
    .tagline span {
        font-size: 8.4rem; }
    .fas {
        font-size: 3rem; } }

@media (max-width: 1600px) and (orientation: landscape) {
    .logo {
        top: 4.5vh; }
    .logo-border {
        height: 150px;
        width: 150px; }
    .logo-name {
        font-size: 2rem; }
    .tagline p {
        font-size: 7rem; }
    .tagline span {
        font-size: 8.4rem; }
    .fas {
        font-size: 2.8rem; } }

@media (max-width: 1450px) and (orientation: landscape) {
    .tagline p {
        font-size: 6.5rem; }
    .tagline span {
        font-size: 8.1rem; } }

@media (max-width: 1360px) and (orientation: landscape) {
    .home .logo {
        top: 5vh;
        .logo-border {
            height: 140px;
            width: 140px; }
        .logo-name {
            font-size: 1.7rem; } }
    .tagline p {
        font-size: 6rem; }
    .tagline span {
        font-size: 7.4rem; } }

@media (max-width: 1260px) and (orientation: landscape) {
    .home .logo {
        top: 4vh;
        .logo-border {
            height: 120px;
            width: 120px; }
        .logo-name {
            font-size: 1.6rem; } }
    .tagline p {
        font-size: 5.2rem; }
    .tagline span {
        font-size: 6.1rem; } }

@media (max-width: 1160px) and (orientation: landscape) {
    .home .logo {
        top: 3.5vh;
        .logo-border {
            height: 100px;
            width: 100px; }
        .logo-name {
            font-size: 1.5rem; } }
    .tagline p {
        font-size: 4.5rem; }
    .tagline span {
        font-size: 5.2rem; } }

@media (max-width: 800px) and (orientation: landscape) {
    .home .logo {
        top: 3vh;
        .logo-border {
            height: 90px;
            width: 90px; }
        .logo-name {
            font-size: 1.4rem; } }
    .tagline p {
        font-size: 4.5rem; }
    .tagline span {
        font-size: 5.3rem; } }

@media (max-width: 660px) and (orientation: landscape) {
    .home .logo {
        top: 2.6vh;
        .logo-border {
            height: 80px;
            width: 80px; }
        .logo-name {
            font-size: 1.3rem; } }
    .tagline p {
        font-size: 4.1rem; }
    .tagline span {
        font-size: 4.6rem; } }

@media (max-width: 550px) and (orientation: landscape) {
    .home .logo {
        top: 2.3vh;
        .logo-border {
            height: 70px;
            width: 70px; }
        .logo-name {
            font-size: 1.2rem; } }
    .tagline p {
        font-size: 3.9rem; }
    .tagline span {
        font-size: 4.5rem; } }

@media (max-width: 470px) and (orientation: landscape) {
    .home .logo {
        top: 2vh;
        .logo-border {
            height: 60px;
            width: 60px; }
        .logo-name {
            font-size: 1.1rem; } }
    .tagline p {
        font-size: 3.5rem; }
    .tagline span {
        font-size: 4.1rem; } }

@media (max-width: 360px) and (orientation: landscape) {
    .home {
        .logo {
            top: 1.5vh; }
        .logo-border {
            height: 55px;
            width: 55px; }
        .logo-name {
            font-size: 1.1rem; } }
    .tagline p {
        font-size: 3.3rem; }
    .tagline span {
        font-size: 3.9rem; } }
