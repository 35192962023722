@mixin button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Cinzel', serif;
    background-color: $dark-color;
    border: $white-border-thin;
    color: $white-color;
    border-radius: 50%;
    outline: none;
    transition: all .6s;
    cursor: pointer; }

@mixin hover-button {
    color: $dark-color;
    background-color: $white-color;
    border: $black-border-thin; }


@mixin border-logo-style {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;
    background-color: $dark-color;
    border: $white-border-thin;
    border-radius: 50%;
    transition: all .6s;
    z-index: 1; }

@mixin logo-name-style {
    font-family: 'Texturina', serif;
    transform: translate(0, -4px); }

@mixin modal-style {
    position: fixed;
    top: 3vh;
    font-size: 1.2rem;
    padding: 0 4vw;
    margin: 0 auto;
    background-color: $white-color;
    height: 94vh;
    overflow: auto;
    opacity: 1;
    transform: translateY(-100vh);
    transition: all .8s ease-out; }
