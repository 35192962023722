@import '../variables.sass';
@import '../mixins.sass';

.offer {
    min-height: 110vh;
    & .products {
        > div {
            display: grid;
            grid-row-gap: 2em;
            justify-content: center;
            margin: 7vh 0; }
        & .product-photo {
            text-shadow: 3px 3px 6px $dark-color;
            color: $white-color;
            padding: 2rem;
            background-color: grey;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            object-fit: cover;
            box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.5);
            border-radius: 6px;
            transition: all .6s;
            & p {
                font-size: 2.5rem; } }
        & .mugs {
            background-image: url('../../../images/mug_1_small.jpg'); }
        & .cups {
            background-image: url('../../../images/cup_1_small.jpg'); }
        & .tea-pots {
            background-image: url('../../../images/teapot_2_small.jpg'); }
        & .plates {
            background-image: url('../../../images/plate_14_small.jpg'); }
        & .bowls {
            background-image: url('../../../images/bowl_1_small.jpg'); }
        & .vases {
            background-image: url('../../../images/vase_4_small.jpg'); }
        & .product-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $white-color;
            padding: 2.5em;
            background-color: $dark-color;
            box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.5);
            border-radius: 6px;
            & p {
                font-size: 1.3rem;
                line-height: 1.8em;
                word-spacing: 2.5px; } }
        & .more-btn {
            @include button;
            margin: 1.2em .8em 0 auto;
            & a {
                font-size: 1.2rem;
                text-decoration: none;
                color: $white-color; }
            &:hover a {
                color: $dark-color; }
            &:hover {
                @include hover-button; } } } }

@media (max-width: 20000px) {
    .products {
        > div {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(400px, 2fr));
            grid-template-rows: repeat(auto-fill, minmax(420px, 2fr)); }
        & .product-photo {
            width: 350px; }
        & .more-btn {
            width: 80px;
            height: 80px; } } }

@media (max-width: 1260px) {
    .products .more-btn {
        width: 75px;
        height: 75px; } }

@media (max-width: 1160px) {
    .products .more-btn {
        width: 70px;
        height: 70px; } }

@media (max-width: 800px) {
    .products .more-btn {
        width: 65px;
        height: 65px; } }

@media (max-width: 550px) {
    .products {
        > div {
            grid-template-columns: 320px;
            grid-template-rows: 400px 460px; }
        & .product-photo {
            width: 320px; }
        & .more-btn {
            width: 60px;
            height: 60px; } } }

@media (max-width: 470px) {
    .products {
        > div {
            grid-template-columns: 270px;
            grid-template-rows: 350px 440px; }
        & .product-photo {
            width: 270px; }
        & .more-btn {
            width: 55px;
            height: 55px; } } }

@media (max-width: 360px) {
    .products {
        > div {
            grid-template-columns: 220px;
            grid-template-rows: 280px 430px; }
        & .product-photo {
            width: 220px; }
        & .more-btn {
            width: 50px;
            height: 50px; } } }
