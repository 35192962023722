@import '../variables.sass';
@import '../mixins.sass';

.shop-footer-modal {
    @include modal-style;
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    word-spacing: 1.5px;
    color: $dark-color;
    padding-bottom: 5vh;
    z-index: 30;
    & .close-btn {
        @include button;
        font-size: 1.06rem;
        color: $white-color;
        margin: 3vh 3vh 1vh auto;
        width: 4.38rem;
        height: 4.38rem;
        &:hover {
            @include hover-button;
            background-color: $white-color; } }
    & div {
        height: auto;
        & h2 {
            margin-bottom: .95em;
            border-bottom: 1px solid black; }
        & p {
            text-align: start;
            white-space: pre-wrap;
            height: auto; } } }

@media (min-width: 551px) {
    .shop-footer-modal {
        width: 80%;
        left: 10vw; } }

@media (max-width: 550px) {
    .shop-footer-modal {
        width: 90%;
        left: 5vw; } }

@media (max-width: 360px) {
    .shop-footer-modal {
        width: 96%;
        left: 2vw; } }
