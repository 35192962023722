@import '../variables.sass';
@import '../mixins.sass';

.cart-modal {
    @include modal-style;
    width: 80%;
    left: 10vw;
    & article {
        margin: 1em 1em 3.3em 1em; }
    & h2 {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 1.2em;
        border-bottom: 1px solid $dark-color; }
    & .close-btn {
        @include button;
        font-size: 1.06rem;
        margin: 3vh 3vh 1vh auto;
        width: 4.38rem;
        height: 4.38rem;
        &:hover {
            @include hover-button; } }
    & .total-price {
        align-self: flex-end;
        font-size: 1.2rem;
        padding: .3em;
        margin-top: 2em;
        background-color: $dark-color;
        color: $white-color;
        border: $black-border-thin;
        border-radius: 6px; }
    & .order {
        display: flex;
        flex-direction: column;
        & .order-menu {
            display: flex;
            background-color: $dark-color;
            color: $white-color;
            margin-bottom: 2vh;
            & > p {
                width: 60%;
                padding: .15em; }
            & .buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                & p {
                    width: 30%;
                    text-align: center; }
                & .delete {
                    width: 7%; } } }
        & .empty-cart {
            align-self: flex-start; }
        & .order-list {
            & li {
                display: flex;
                padding: .3em 0 0 .15em;
                & > p {
                    width: 60%; }
                & .buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & p {
                        width: 30%;
                        text-align: center; }
                    & .change-quantity-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 30%;
                        & p {
                            text-align: center; }
                        & .change-quantity {
                            @include button;
                            font-size: 1.88rem;
                            margin: .3vh 0;
                            width: 2.19rem;
                            height: 2.19rem;
                            &:hover {
                                @include hover-button; } } }
                    & .delete {
                        color: $dark-color;
                        margin: .3vh 0;
                        outline: none;
                        background-color: transparent;
                        border: none;
                        width: 7%;
                        & i {
                            font-size: 1.7rem; }
                        &:hover {
                            cursor: pointer; } } } } } }
    & .delivery {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & .delivery-options {
            width: 100%;
            & ul {
                display: grid;
                list-style: none;
                & li {
                    display: flex;
                    flex-direction: column;
                    & label {
                        cursor: pointer;
                        & input {
                            margin-right: 1em; } }
                    & p {
                        margin: .5em 0 0 1.8em; } } } }
        & div {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            & .estimated-delivery-date {
                padding: .3em;
                & span:last-child {
                    font-size: 1.1rem;
                    padding-left: .3em;
                    font-weight: 900; } } } }
    & .address {
        & .address-form form {
            display: flex;
            flex-direction: column;
            padding: .3em;
            & input {
                align-self: flex-start;
                background-color: transparent;
                backdrop-filter: $blur-effect;
                border: $black-border-thin;
                border-radius: 6px;
                outline: none;
                height: 1.6rem; }
            & label {
                font-size: 1rem;
                margin-top: 1vh; } }
        & .send-order {
            @include button;
            font-size: 1.3rem;
            margin: 3vh 3vh 3vh auto;
            width: 7.2rem;
            height: 7.2rem;
            &:hover {
                @include hover-button; } } } }

@media (max-width: 20000px) {
    .delivery {
        .delivery-options ul {
            grid-template-columns: repeat(3, 1fr); }
        div {
            flex-direction: row; } }
    .order {
        .order-menu {
            .buttons {
                width: 40%; } }
        .order-list {
            & li {
                flex-direction: row;
                .buttons {
                    width: 40%; } } } }
    .address .address-form form input {
        width: 40%; } }

@media (max-width: 1024px) {
    .delivery {
        .delivery-options ul {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 1.5em; }
        div {
            margin-top: 2em;
            flex-direction: column; } }
    .order {
        .order-menu .buttons p {
            display: none; }
        .order-list {
            & li {
                flex-direction: column;
                margin-bottom: 1.2em;
                .buttons {
                    width: 80%; } } } }
    .address .address-form form input {
        width: 50%; } }

@media (max-width: 800px) {
    .address .address-form form input {
        width: 60%; } }

@media (max-width: 550px) {
    .cart-modal {
        width: 90%;
        left: 5vw; } }

@media (max-width: 470px) {
    .address .address-form form input {
        width: 70%; } }

@media (max-width: 360px) {
    .cart-modal {
        width: 96%;
        left: 2vw;
        .address .address-form form input {
            width: 80%; } } }
